import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { NavButton } from "./NavButton";
import { NavElement } from "./NavElement";
import { mainLinks } from "config";
import logo from "assets/logo.svg";

import authentication from "services/auth";

export const Nav = () => {
  const [lastLocation, setLastLocation] = useState("");
  const [open, setOpen] = useState(false);

  const { pathname } = useLocation();

  const history = useHistory();

  useEffect(() => {
    if (lastLocation && lastLocation !== pathname) {
      setOpen(false);
    }
    setLastLocation(pathname);
  }, [pathname, lastLocation]);

  console.log(history);

  const handleLogout = () => {
    authentication.logout();
    history.push("/login");
  };

  return (
    <>
      <nav className="nav">
        <div
          className="container-big row flex-align-center"
          style={{ height: "100%" }}
        >
          <div className="col-8">
            <Link style={{ marginRight: "15px" }} to="/">
              Dashboard
            </Link>
            <Link style={{ marginRight: "15px" }} to="/listing">
              Kunden Liste
            </Link>
            <Link to="/logs">Logs</Link>
          </div>
          {history.location.pathname === "/login" ? null : (
            <div className="col-4 flexbox justify-end">
              <button onClick={() => handleLogout()} className="btn">
                Abmelden
              </button>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};
