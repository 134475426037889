import React, { Component, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import { Nav } from './Nav';
import { Footer } from './Footer';

import ProtectedRoute from './protectedRoute';
import { AuthProvider } from './authProvider';

const Home = React.lazy(() => import('../pages/Home'));
const Logs = React.lazy(() => import('../pages/Logs'));
const Clients = React.lazy(() => import('../pages/Client'));
const Client = React.lazy(() => import('../pages/Client/singleClient'));
const Login = React.lazy(() => import('../pages/Login'));
const CreateClient = React.lazy(() => import('../pages/Client/createClient'));

// const Legal = React.lazy(() => import('../pages/Legal'));

export default class MainRouter extends Component {
  render() {
    return (
      <AuthProvider>
        <Nav />

        <Suspense fallback={null}>
          <div className="main-content">
            <Switch>
              {/* <Route exact path="/legal/:slug" component={Legal} /> */}
              <Route exact path="/login" component={Login} />
              <ProtectedRoute exact path="/" component={Home} />
              <ProtectedRoute exact path="/listing" component={Clients} />
              <ProtectedRoute exact path="/listing/:id" component={Client} />
              <ProtectedRoute exact path="/create/" component={CreateClient} />
              <ProtectedRoute exact path="/logs/" component={Logs} />
            </Switch>
            {/* <Footer /> */}
          </div>
        </Suspense>
      </AuthProvider>
    );
  }
}
